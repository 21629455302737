<template>
  <div class="page">
    <IntroDataImp class="section" />
    <TheFooter class="section" />
  </div>
</template>

<script>
import IntroDataImp from "@/components/common/introblock/IntroDataImp.vue";
import TheFooter from "../components/common/footer/TheFooter.vue";
export default {
  components: {
    IntroDataImp,
    TheFooter,
  },
};
</script>
<style
  lang="
    scss"
></style>
